import { registerApplication, start, LifeCycles } from 'single-spa';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvProps } from './shared/env.props';
import { FormativesType } from './shared/formatives.types';
import { BreadCrumb, EducatorUiProps } from './shared/educator-ui.props';
import { UserInfo } from '../../educator-ui/src/fetch-services/user.service.types';
import { Organization } from '../../educator-ui/src/fetch-services/org.service.types';

declare global {
  interface Window {
    APP_ENV: EnvProps | undefined;
  }
}

if (window.APP_ENV === undefined) {
  window.APP_ENV = {
    authority: process.env.authority!,
    mps_ui_assessment_url: process.env.mps_ui_assessment_url!,
    ulpRedirectUrl: process.env.ulpRedirectUrl!,
    pssBaseUrl: process.env.pssBaseUrl!,
    adminApiUrl: process.env.adminApiUrl!,
    userApiUrl: process.env.userApiUrl!,
    lmsApiUrl: process.env.lmsApiUrl!,
    lmsFlagrUrl: process.env.lmsFlagrUrl!
  };
}

const productCode = 'Imagine+Assessment';
const createSubjectObservable = <T>(
  initialValue: T
): { subject: BehaviorSubject<T>; observable: Observable<T> } => {
  const subject = new BehaviorSubject(initialValue);
  const observable = subject.asObservable();
  return { subject, observable };
};

const {
  subject: accessTokenBehaviorSubject,
  observable: accessTokenObservable
} = createSubjectObservable<string>('');
const { subject: pageTitleBehaviorSubject, observable: pageTitleObservable } =
  createSubjectObservable<string>('');
const { subject: formativesBehaviorSubject, observable: formativesObservable } =
  createSubjectObservable<FormativesType>({} as FormativesType);
const { subject: breadCrumbBehaviorSubject, observable: breadCrumbObservable } =
  createSubjectObservable<BreadCrumb>({ crumbs: [] });
const {
  subject: defaultBoolBehaviorSubject,
  observable: defaultBoolObservable
} = createSubjectObservable<boolean>(true);
const { subject: userBehaviorSubject, observable: userObservable } =
  createSubjectObservable<UserInfo>({} as UserInfo);
const {
  subject: organizationBehaviorSubject,
  observable: organizationObservable
} = createSubjectObservable<Organization>({} as Organization);

const educatorUiProps: EducatorUiProps = {
  accessTokenBehaviorSubject,
  pageTitleBehaviorSubject,
  pageTitleObservable,
  formativesBehaviorSubject,
  breadCrumbBehaviorSubject,
  breadCrumbObservable,
  userBehaviorSubject,
  organizationBehaviorSubject
};

registerApplication({
  name: '@assessment/educator-ui',
  app: () => System.import<LifeCycles>('@assessment/educator-ui'),
  activeWhen: (location) => location.pathname.includes('/'),
  customProps: educatorUiProps
});

registerApplication({
  name: 'ipa-educator-home',
  app: () => System.import<LifeCycles>('ipa-educator-home'),
  activeWhen: (location) => location.pathname.includes('/home'),
  customProps: {
    domElement: document.getElementById('ipa-educator-home'),
    accessTokenObservable,
    userObservable
  }
});

registerApplication({
  name: 'assessment-scheduler',
  app: () => System.import<LifeCycles>('assessment-scheduler'),
  activeWhen: (location) => location.pathname.includes('/scheduling'),
  customProps: {
    domElement: document.getElementById('assessment-scheduler'),
    accessTokenObservable,
    organizationObservable,
    breadCrumbBehaviorSubject,
    env: {
      myPathAdminServiceUrl: window.APP_ENV.adminApiUrl,
      myPathLmsServiceUrl: window.APP_ENV.lmsApiUrl,
      myPathUserServiceUrl: window.APP_ENV.userApiUrl
    }
  }
});

registerApplication({
  name: '@wne/assignment-builder-ui',
  app: () => System.import<LifeCycles>('@wne/assignment-builder-ui'),
  activeWhen: (location) => location.pathname.includes('/assignmentbuilder'),
  customProps: {
    domElement: document.getElementById('assignment-builder'),
    baseUrl: System.resolve('@wne/assignment-builder-ui'),
    tokenSubject: accessTokenBehaviorSubject,
    assignmentBuilderCustomPropsSubjectObservable: formativesObservable,
    assignmentBuilderCustomPropsSubject: formativesBehaviorSubject,
    assignmentBuilderBreadcrumbSubject: breadCrumbBehaviorSubject,
    productCode,
    env: {
      REACT_APP_ASSIGNMENT_BUILDER_API_BASE_URL:
        window.APP_ENV.assignment_builder_url,
      REACT_APP_MPNG_API_BASE_URL: window.APP_ENV.mpng_api_base_url,
      REACT_APP_FLAGR_BASE_URL_ASSIGNMENT_BUILDER:
        window.APP_ENV.flagr_base_url_assignment_builder,
      REACT_APP_DOOLITTLE_API_BASE_URL: window.APP_ENV.doolittle_api_base_url,
      REACT_APP_DOOLITTLE_READER_BASE_URL:
        window.APP_ENV.doolittle_reader_base_url,
      REACT_APP_ALA_EDUCATOR_URL: window.APP_ENV.userApiUrl,
      REACT_APP_GRAPHQL_API_URL: window.APP_ENV.graphql_api_url,
      REACT_APP_FIXEDFORM_PLAYER_URL: window.APP_ENV.assessment_player_url,
      REACT_APP_FIXEDFORM_PLAYER_CTK_ENV_URL:
        window.APP_ENV.assessment_player_ctk_env_url,
      REACT_APP_FIXEDFORM_PLAYER_CTK_ENV_ID:
        window.APP_ENV.assessment_player_ctk_env_id,
      REACT_APP_ITEM_ENGINE_AUTHOR_URI: '',
      REACT_APP_ITEM_ENGINE_ITEMS_URI: ''
    }
  }
});

registerApplication({
  name: '@wne/mypath-assessment-reports',
  app: () => System.import<LifeCycles>('@wne/mypath-assessment-reports'),
  activeWhen: (location) =>
    location.pathname.includes('/mypath-assessment-reports'),
  customProps: {
    domElement: document.getElementById('diagnostic-report'),
    authTokenSubjectObservable: accessTokenObservable,
    MyPathLevelSubject: pageTitleBehaviorSubject,
    MyPathLevelSubjectObservable: pageTitleObservable,
    MyPathReassignSubject: defaultBoolBehaviorSubject,
    MyPathReassignButtonObservable: defaultBoolObservable,
    mypathReportActiveFlagsSubject: new BehaviorSubject([
      'MPAReport-AllowCSVExportAllData',
      'MPAReport-StudentIDCSVColumn',
      'MPAReport-DomainReporting'
    ]),
    MyPathLoaderSubject: defaultBoolBehaviorSubject,
    MyPathBreadcrumbsSubject: breadCrumbBehaviorSubject,
    productCode,
    sentryEnv: `${window.APP_ENV.sentry_env}`,
    env: {
      REACT_APP_FLAGR_API_BASE_URL: window.APP_ENV.lmsFlagrUrl
    }
  }
});

start({
  urlRerouteOnly: true
});
